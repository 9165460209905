<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          v-if="$acl.can('professionalCreateEdit')"
          @click="handleNewProfessional()"
          >Novo</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="professionals.data"
          :items-per-page='-1'
          :loading="loading"
          :options.sync="options"          
          @update:options="handleTableUpdate($event)"
          @click:row="handleNewProfessional($event.id)"
          hide-default-footer
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:[`item.doc`]="{ item }">
            <span v-if="item.person_type == 'fisica'">
              {{ $format.cpf(item.cpf) }}
            </span>
            <span v-if="item.person_type == 'juridica'">
              {{ $format.cnpj(item.cnpj) }}
            </span>
          </template>
        </v-data-table>
        <v-pagination
          v-model="searchParams.page"
          :length="professionals.last_page"
          @input="select($event)"
        ></v-pagination>
      </v-col>
    </v-row>
    <StoreProfessionalForm @store="select()" ref="StoreProfessionalForm" />
  </div>
</template>

<script>
import StoreProfessionalForm from "@/components/professionals/forms/StoreProfessionalForm.vue";

export default {
  components: { StoreProfessionalForm },

  data: () => ({
    headers: [
      { text: "Código", align: "center", value: "id" },
      { text: "Name", align: "start", value: "name" },
      { text: "Tipo de pessoa", align: "center", value: "person_type" },
      { text: "CNPJ/CPF", align: "center", value: "doc" },
      { text: "Status", align: "center", value: "status" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
    },

    professionals: {
      data: [],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    select(page) {
      this.searchParams.page = page;
      this.$http
        .index("professional/professional", this.searchParams)
        .then((response) => {
          this.professionals = response.professionals;
        });
    },

    handleTableUpdate(options) {
      this.options = options;
      //this.searchParams.page = options.page
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleNewProfessional(id = null) {
      if (this.$acl.can("professionalCreateEdit")) {
      this.$refs.StoreProfessionalForm.open(id);
      }
    },
  },
};
</script>

<style></style>
